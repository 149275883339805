import { Link } from "gatsby";
import { isString } from 'lodash';
import React, { useState } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import PlayVideo from "../../Play/PlayVideo";
import { getVideoId } from "../../utils";
import YouTube from "react-youtube";
import "./VideoCard.scss";
import classNames from "classnames";

const VideoCard = (props) => {
    const [isPlaying, setPlaying] = useState(false);
    return (
        <div className="video-card">
            <figure>
                <ScrollAnimation animateIn="fadeInUp" className={classNames("img-zoom position-relative h-100", {"video-inline-h": (isPlaying && props.playInline) })} animateOnce>
                    {(isString(props.cardImg)) ? (
                        <img src={props.cardImg} alt={props?.cardImgAlt} />
                    ):(
                        <>{props.cardImg}</>
                    )}
                    {(props.video && !isPlaying) && <strong
                        className="play-btn"
                        onClick={(e) => {
                            setPlaying(true);
                        }}
                    >
                        <i className="icon-play"></i>
                    </strong>}
                    {(props.video && isPlaying) && 
                        <strong
                            className="play-btn play-btn-close"
                            onClick={(e) => {
                                setPlaying(false);
                            }}
                        >
                            <i className="icon-close"></i>
                        </strong>
                    }
                    {(props.video && props.playInline) && <div className="inline-video inline-video-container">
                        {isPlaying && 
                            <React.Fragment>
                                <YouTube
                                    className="inline-video"
                                    containerClassName="sa"
                                    videoId={getVideoId(props?.video)}
                                    opts={{
                                        height: "100%",
                                        width: "100%",
                                        playerVars: {
                                            autoplay: 1,
                                            disablekb: 1,
                                            showinfo: 0,
                                            controls: 1,
                                            title: 0,
                                            loop: 1,
                                            modestbranding: 0
                                        }
                                    }}
                                />
                            </React.Fragment>
                        }
                    </div>}
                </ScrollAnimation>
            </figure>
            {props.cardTitle && <div className="info">
                <h6 className="card-title w-100">{props.cardTitle}</h6>
                <a href="javascrip:;" className="team-link text-uppercase">
                    {props.searchLink}
                </a>
            </div>}
            {/* <PlayVideo
                isOpen={isPlaying}
                isCloseFunction={setPlaying}
                videoId="pXyXoWAuw58"
                isAutoPlay={1}
            /> */}
        </div>
    );
};

export default VideoCard;
