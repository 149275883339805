import { Link } from "gatsby";
import * as React from "react";
import ImageTransform from "../../common/ggfx-client/module/components/image-transform";
import "./PropertyCard.scss";

const PropertyCard = (props) => {
    let processedImages = JSON.stringify({});
    if (props?.property?.imagetransforms?.images_Transforms) {
        processedImages = props.property.imagetransforms.images_Transforms;
    }
    const getPropertyLink = (hit) => {
        const base = `property-${props?.property?.search_type === "sales" ? "for-sale" : "for-rent"}/`;
        if (hit?.developer)
            return `/off-plan-property/${hit.slug}-${hit.id}/`
        else
            return `/${base}${hit.slug}-${hit.id}/`
    }
    return (
        <div className="property-card">
            <Link to={getPropertyLink(props?.property)} className="w-100">
                <figure className="img-zoom property-tab-img">
                    <ImageTransform
                        imagesources={props.cardImg}
                        renderer="srcSet"
                        imagename={props?.imagename}
                        attr={props?.attr}
                        imagetransformresult={processedImages}
                        id={props.id}
                    />
                    <div class="bg-gradient-module"></div>
                </figure>
            </Link>
            <div className="info d-md-flex justify-content-between align-items-md-center align-items-start property-title">
                <h6 className="card-title">
                    {props.property && <Link to={getPropertyLink(props?.property)}>{props.cardTitle}</Link>}
                </h6>
                <p className="card-price">
                    {props.searchType === "sales" ? "For Sale " : "To Rent "} <strong> {props.cardPrice}</strong>
                </p>
            </div>
            <p className="card-desc">{props.cardDesc}</p>
            <div className="Feature-list">
                <span>
                <i className="icon-bed"></i>
                    {props.totalBed}
                </span>
                {props?.totalSize && (
                    <span>
                    <i className="icon-area"></i>
                        {props.totalSize}
                    </span>
                )}
            </div>
        </div>
    );
};

export default PropertyCard;
