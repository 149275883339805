import {Link}  from "gatsby"
import React, {useState, useEffect} from "react";
import Slider from "react-slick";
import { Container } from 'react-bootstrap';
// Images
import  './FeaturedProperty.scss'; 
import ScrollAnimation from "react-animate-on-scroll";
import axios from "axios";
import PropertyCard from "../Home/PropertyCard/PropertyCard";
import { GetURL } from "../utils";

const FeaturedProperties = (props) => {
    
    const [propItems, setPropItems] = useState([]);

    useEffect(() => {
        // similar properties
        if(props.hasData) {
            if(props.properties) {
                setPropItems(props.properties)
            }
        } else {
            let query = "";
            if(props.query) {
                query = "-"+props.query;
            }
            let url = process.env.GATSBY_STRAPI_SRC + `/stb-lists/item/featured` + query;
            if(!props.hasData || undefined){
                getItems(url);
            }
            
        }
        // similar properties
    }, []);

    const getItems = async url => {
        try {
            const { data } = await axios.get(url, {
                headers: {
                Authorization: `Bearer ${process.env.GATSBY_STRAPI_FORM_TOKEN}`,
                }})
            if (data && data.length > 0) {
                setPropItems(data)
            }
        } catch (error) {
            console.error(error)
        }
    }

    const myRefs = React.createRef()

    const settings1 = {
        dots: false,
        speed: 800,
        autoplay: propItems.length > 2 ? true : false,
        autoplaySpeed: 4000,
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: propItems.length > 2 ? true : false,
        arrows: false,
        mobileFirst: true,
        centerMode: false,
        variableWidth: true,
        centerPadding: "18px",
        responsive: [
          {
            breakpoint: 1199,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 576,
            settings: {
              autoplay: false,
              slidesToShow: 1,
            },
          },
        ],
      }
  

    return(
        <div className="property-tabsec similar-properties-details">
            <Container>
                <ScrollAnimation animateIn="fadeInUp" animateOnce>
                    <div className="similar-head d-md-flex justify-content-between align-items-center">
                        <h2 className="main-title">{props?.data?.Title}</h2>
                        {(props.data && props.data.Cta_Link) && <Link to={`/${GetURL(props.data.Cta_Link._id)}`} className="view-more text-uppercase d-none d-md-inline-block">view more</Link>}
                        {props.email && <Link to={`/properties-for-${propItems?.some(c => c.search_type === "sales") ? "sale" : "rent"}/in-dubai/?id:${props?.id}`} className="view-more text-uppercase d-none d-md-inline-block" >view more</Link>}
                    </div>
                </ScrollAnimation>
                <Slider className="banner-imgslider d-block" {...settings1}
                    ref={myRefs}
                >
                    {propItems.map((property, i) =>
                        <div className="img-item" key={i}>
                            <PropertyCard
                                cardImg={property.images.filter(c => c.url != null)[0].url}
                                imagename="property.images.detail"
                                id={property._id}
                                attr={{ class: '', alt: property.display_address + " - Espace" }}
                                property={property}
                                cardTitle={property.title}
                                searchType={property.search_type}
                                cardPrice={`AED ${property.price.toLocaleString()}`}
                                cardDesc={property.display_address}
                                totalBed={`${property.bedroom} Bedrooms`}
                                totalSize={`${property.floorarea_min} ${property.floorarea_type}.`}
                            />
                        </div>
                    )}
                </Slider>
                {
                    propItems.length > 2 &&
                    <div className="card-slider">
                        <button
                            type="button"
                            className="slick-arrow main slick-prev d-block"
                            onClick={() => {
                            myRefs.current.slickPrev()
                            }}
                        >
                            {" "}
                            Previous
                        </button>
                        <button
                            type="button"
                            className="slick-arrow main slick-next d-block"
                            onClick={() => {
                            myRefs.current.slickNext()
                            }}
                        >
                            {" "}
                            Next
                        </button>
                    </div>
                }
            </Container>
        </div>  
    )
}

export default FeaturedProperties;